import React, { Fragment, useState, useEffect, useRef } from 'react';
import {
    Grid,
    TextField,
    FormControl,
    Button,
    MenuItem,
    List,
    ListItem,
    ListItemText,
    Select,
    ListItemIcon,
    Paper,
    Box,
    Checkbox,
    Card,
    CardHeader,
    Divider,
    InputBase,
    IconButton

} from '@material-ui/core';
import { useStyles } from '../../styles/style';
import axios from 'axios';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { content_type, tags, operators, fields, name as radioList} from './objects';
import BucketQuery from './bucketQuery';
import BucketForm from './bucketForm';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import NoImage from './../../images/no_image.png'
import cogoToast from 'cogo-toast';
import SearchIcon from '@material-ui/icons/Search';
import { ReactTransliterate } from "react-transliterate";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import MultiLanguageModal from '../Reusable/multiLanguageModal';
// import {Language} from 'react-transliterate'
import { game_content_type } from './objects'
import { tr } from 'date-fns/locale';



function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function CreateBucket(props) {
    const classes = useStyles();
    const initialState = {
        row: "",
        cms_name:"",
        size: "",
        enable: "true",
        more: "true",
        magnification:1,
        shortDescription:"",
        url:"",
        proUser:""
    }
    const textRef = useRef();
    const [tag, setTag] = useState([]);
    const [initialValues, setInitialValues] = useState(initialState)
    const [liveDate, setLiveDate] = useState(new Date());
    const [expiryDate, setExpiryDate] = useState(new Date());
    const [media, setMedia] = useState("");
    const [option1, setOption1] = useState(fields[0]);
    const [option2, setOption2] = useState("Equal");
    const [option3, setOption3] = useState("");
    const [text, setText] = useState("");
    const [query, setQuery] = useState([]);
    const [bucketStyle, setBucketStyle] = useState("");
    const [modal, setModal] = useState(false);
    const [englishLang,setEnglishLang] = useState("");
    const [hindiLang, setHindiLang] = useState("");
    const [marathiLang, setMarathiiLang] = useState("");
    const [kannadaLang, setKannadaLang] = useState("");
    const [teluguLang, setTeluguLang] = useState("");
    const [punjabiLang, setPunjabiLang] = useState("");
    const [tamilLang, setTamilLang] = useState("");
    const [gujaratiLang, setGujaratiLang] = useState("");

    const [malayalamLang, setMalayalamLang] = useState("");
    const [bengaliLang, setBengaliLang] = useState("");
    const [odisaLang, setOdisaLang] = useState("");
    const [urduLang, setUrduLang] = useState("");
    const [sinhalaLang, setSinhalaLang] = useState("");
    const [khmerLang, setKhmerLang] = useState("");
    const [arabicLang, setArabicLang] = useState("");
    const [burmeseLang, setBurmeseLang] = useState("");
    const [afganiLang, setAfganiLang] = useState("");

    const [andOr, setAndOr] = useState([]);
    const [pages, setPages] = useState([]);
    const [singlePage, setSinglePage] = useState('60bb7637d481516c2cf7cf6e')
    const [queryResult, setQueryResult] = useState([]);
    const [leftData, setLeftData] = useState([]);
    const [checked, setChecked] = React.useState([]);
    const [varient, setVarient] = React.useState("");
    const [langList, setLangList] = useState([{ code: "Default", name: "Default" }])
    const [lang, setLang] = useState("Default")
    const [searched, setSearched] = useState("")
    const [content, setContent] = useState("")
    const [onpost, setOnPost] = useState(false)
    const [continueToggle, setcontinueToggle] = useState(false)
    const [proToggle, setproToggle] = useState(false)
    
    // const [langs, setLangs] = useState < Language > ("hi");
    const queryContentType = content_type.filter(ele=>{
        return ele.Description != "charts"
    })


    const varientArray = ["none", "v1", "v2", "v3", "v4", "v5", "v6"]
    var bucketSTyleList = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14"];

    const handleContentChange = (e) => {
        setContent(e.target.value);
    }


    const fetchLanguage = async () => {
        await axios.get(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/type/language?storeId=${localStorage.getItem("storeId")}`).then(res => {
            setLangList([...langList, ...res.data.result.result])
        }).catch(
            err => {
                console.log(err)
            }
        )
    }

    const handleChangeLang = (e) => {
        setLang(e.target.value)
    }

    const fetchPages = async () => {
        const url = process.env.REACT_APP_CMS_CONTENT_IP
        await axios.get(`${url}/v1/content/type/page?storeId=${localStorage.getItem("storeId")}`).then(res => {
            setPages(res.data.result.result)
        }).catch(
            err => {
                console.log(err)
            }
        )
    }


    useEffect(() => {
        fetchLanguage();
        fetchPages()
    }, [])
 
    const handleChangeToggle = (e) =>{
        console.log('ee',e)
        setcontinueToggle(e)
    }

    const handleproToggle = (e) =>{
        console.log('ee',e)
        setproToggle(e)
    }

    const onHandleChangeData = (e) => {
        const value = e.target.value;
        setInitialValues({
            ...initialValues,
            [e.target.name]: value
        })

    }
    const handleTagChange = (event) => {
        setTag(event.target.value);
        console.log('tag==>',tag)
    };

    const onLiveDateChange = (e) => {
        setLiveDate(e)
    }
    const onexpiryDateChange = (e) => {
        setExpiryDate(e)
    }

    const medialHandle = (e) => {
        setMedia([...media, ...e.target.files])
    }

    const handlePageChange = (event) => {
        setSinglePage(event.target.value);
    }


    const optionOneHandleChange = (e) => {
        setOption1(e.target.value);
        setText("");

    }

    // useEffect(() => {
    //     if (option1) {
    //         textRef.current.focus()
    //     }

    // }, [option1])

    const optionTwoHandleChange = (e) => {
        setOption2(e.target.value)
    }

    const optionThreeHandleChange = (e) => {
        setOption3(e.target.value);
        setText(e.target.value);
    }

    const onTextChange = (e) => {
        setText(e.target.value);
    }

    const onAndOrChange = (e, index) => {
        const values = [...andOr];
        values[index].AND_OR = e.target.value;
        setAndOr(values)
        // setAndOr(e.target.value);
        // query.map((ele, i) => {
        //     if (i === index + 1) {
        //         ele.AND_OR = e.target.value
        //     }

        // })
        let data = query;
        data[index]['AND_OR'] = e.target.value

        setQuery(data)
    }

    // query table functions

    const leftChecked = intersection(checked, leftData);
    const rightChecked = intersection(checked, queryResult);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };
    const deleteLeftData =() =>{
        setLeftData(not(leftData, leftChecked));
        setChecked(not(checked, leftChecked));
    }

    const handleCheckedRight = () => {
        setQueryResult(queryResult.concat(leftChecked));
        setLeftData(not(leftData, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeftData(leftData.concat(rightChecked));
        setQueryResult(not(queryResult, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(leftData);
        const [reorderItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderItem);

        setLeftData(items)


    }

    const handleVarintChange = (e, index) => {
        leftData.map((ele, i) => {

            if (i === index) {
                ele.defaultVarient = e.target.value
            }

        })
    }

    const right = (title, items) => (
        (items && items.length > 0 ?
            <Card>
                <CardHeader
                    className={classes.cardHeader}
                    avatar={
                        <Checkbox
                            onClick={handleToggleAll(items)}
                            checked={numberOfChecked(items) === items.length && items.length !== 0}
                            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                            disabled={items.length === 0}
                            inputProps={{ 'aria-label': 'all items selected' }}
                        />
                    }
                    title={title}
                    subheader={`${numberOfChecked(items)}/${items.length} selected`}
                />

                <Divider />
                <div className={classes.contentSearch}>
                    <div className={classes.searchIcon}>
                        <SearchIcon />
                    </div>
                    <InputBase
                        placeholder="Search…"
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        value={searched}
                        onChange={(e) => setSearched(e.target.value)}
                        inputProps={{ 'aria-label': 'search' }}
                    />
                </div>


                <List className={classes.list} dense component="div" role="list">
                    {items.
                        filter(val => {
                            if (searched === "") {
                                return val
                            } else if (val.title.toLowerCase().includes(searched.toLowerCase())) {
                                return val
                            }
                        }).map((value) => {
                            const labelId = `${value._id}`;

                            return (
                                <ListItem key={value._id} role="listitem" button onClick={handleToggle(value)}>
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={checked.indexOf(value) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <img src={value.image ? value.image : NoImage} height="50" width="50" />


                                    <ListItemText id={labelId} primary={value.title} />
                                </ListItem>
                            );
                        })}
                    <ListItem />
                </List>
            </Card> :
            <Card>
                <CardHeader
                    className={classes.cardHeader}
                    avatar={
                        <Checkbox
                            inputProps={{ 'aria-label': 'all items selected' }}
                        />
                    }
                    title={title}
                    subheader="no data"
                />
                <Divider />

                <List className={classes.list} dense component="div" role="list">
                    <p className="text-center">no data</p>
                    <ListItem />
                </List>
            </Card>)

    );


    const left = (title, items) => (
        <Card >
            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                        disabled={items.length === 0}
                        inputProps={{ 'aria-label': 'all items selected' }}
                    />
                    
                }
                action={
                    <IconButton aria-label="delete" onClick={deleteLeftData} disabled={leftChecked.length === 0}>
                        <DeleteForeverOutlinedIcon className={leftChecked.length === 0?null:classes.btndelete}/>
                    </IconButton>
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selected`}
            />
            <Divider />
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="contents" >
                    {(provided) => (
                        <List className={classes.list} dense component="div" role="list" {...provided.droppableProps} ref={provided.innerRef}>
                            {items.map((value, index) => {
                                const labelId = `${value.id}`;

                                return (
                                    <Draggable key={value.id} draggableId={value.id} index={index}>
                                        {(provided) => (
                                            <ListItem role="listitem" button onClick={handleToggle(value)} {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        checked={checked.indexOf(value) !== -1}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </ListItemIcon>
                                                <img src={value.image ? value.image : NoImage} height="50" width="50" />

                                                <ListItemText id={labelId}>
                                                    <Grid container>
                                                        <Grid item md={6}>
                                                            <p className="mt-4 ml-1">{value.title}</p>
                                                        </Grid>
                                                        <Grid item md={6}>
                                                            <FormControl variant="outlined" className={classes.formControl} style={{ minWidth: "50%" }}>

                                                                <Select
                                                                    id="demo-simple-select-outlined"
                                                                    value={value.defaultVarient}
                                                                    onChange={(e) => handleVarintChange(e, index)}
                                                                >{value.varient.map(ele => (
                                                                    <MenuItem value={ele}>{ele}</MenuItem>

                                                                ))
                                                                    }


                                                                </Select>

                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>



                                                </ListItemText>


                                            </ListItem>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </List>
                    )}
                </Droppable>
            </DragDropContext>

        </Card>
    );


    // end querytable functions
    const onSubmitquery = () => {
        const values = [...andOr]
        values.push({ AND_OR: '' })
        setAndOr(values)
        setQuery([...query, { "AND_OR": "", "field": option1, "operators": option2, "text": text }]);


    }


    const toggle = () => setModal(!modal);



    const ondeleteQuery = (e) => {
        let filtervalue = query.filter((ele, i) => {
            if (e === i) {
                return i !== e
            } else {
                return ele
            }
        })
        setQuery(filtervalue)
        if (query.length <= 1) {
            setAndOr("")
        }
    }

    const bucketStyleChange = (e) => {

        setBucketStyle(e.target.value)
    }


    const postBucketApi = async (data) => {

        const fd = new FormData();
        fd.append("type", data.type);
        fd.append("language", data.language);
        fd.append("contentType", data.contentType);
        fd.append("names", JSON.stringify(data.names));
        fd.append("enabled", data.enabled);
        fd.append("live_date_time", data.live_date_time);
        fd.append("expiry", data.expiry);
        fd.append("row", data.row);
        fd.append("cms_name", data.cms_name);
        fd.append("size", data.size);
        fd.append("more", data.more);
        fd.append("bucketStyle", data.bucketStyle);
        fd.append("query", JSON.stringify(data.query));
        fd.append("queryData", JSON.stringify(data.queryData));
        // fd.append("tag", data.tag);
        fd.append("page", data.page);
        fd.append("magnification", data.magnification);
        fd.append("storeId", data.storeId);
        fd.append('continueStatus',data.continueStatus)
        fd.append('shortDescription',data.shortDescription)
        fd.append('proUser',data.proUser)
        fd.append('url',data.url)
        for (let index = 0; index < data.media.length; index++) {
            const element = data.media[index];
            fd.append('file', element)
        }
        for (let index = 0; index < data.tag.length; index++) {
            const element = data.tag[index];
            fd.append(`tag[${index}]`, element)
        }
        if(data.tag.length == 0){
            fd.append(`tag[]`,[])
        }
        // 
        await axios.post(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content`, fd, { headers: { "Content-Type": "multipart/form-data" } })
            .then(
                res => {
                    if (res.status === 200) {
                        cogoToast.success("content created Succesfully")
                        window.location.reload()

                    }
                }
            ).catch(err => {
                console.log(err.response)
            })

    }

    const postQuery = async (data,arrays) => {
        await axios.post(`${process.env.REACT_APP_CMS_CONTENT_IP}/v1/content/query`, data)
            .then(
                res => {
                    let array = [];
                    console.log('-',res.data.length)
                    if (res.data.length > 0) {
                        const data = res.data
                        console.log('res_data',res.data);
                        const newResultData = data.map((ele, i) => {
                            if((ele.data.id).includes('playlist-')){
                            array.push((ele.data.id).replace('playlist-',''));
                            }
                            else if((ele.data.id).includes('artist-')){
                                array.push((ele.data.id).replace('artist-',''));
                            }
                            else{
                                array.push((ele.data.id)) 
                            }
                            let obj = {
                                id: ele.data.id,
                                image: ele.data.image,
                                title: ele.data.title,
                                varient: varientArray,
                                defaultVarient: ""
                            }
                            return obj
                        })
                        let filterObj = newResultData.filter(qId => !leftData.some(o => o.id === qId.id))

                        setQueryResult(filterObj)
                        // for(let i in arrays){
                        //     for(let j in res.data){
                        //         if((res.data[j].data.id).includes(arrays[i])){
                        //             console.log('>>>>')
                        //         }
                        //         else{
                        //             array.push(arrays[i]);
                        //             console.log('<<<<',`${arrays[i]},No data Found`)
                        //         }
                        //     }
                        // }
                        console.log('--',arrays)
                        console.log('--',array)
                        let NewArray = arrays.filter((x) => !array.includes(x))
                        if(NewArray.length > 0){
                            cogoToast.error(`${NewArray} No data Found`);
                            }
                    }
                    else{
                        cogoToast.error("No data Found");
                    }
                }
            ).catch(err => {
                console.log(err.response)
                cogoToast.error("Bad Request Solr Error")
                setQueryResult([])
            })

    }

    // convert string to boolean
    var str2bool = (value) => {
        if (value && typeof value === "string") {
            if (value.toLowerCase() === "true") return true;
            if (value.toLowerCase() === "false") return false;
        }
        return value;
    }


    const onSubmit = () => {
        const QueryData = leftData.map((ele, i) => {
            let obj = {
                id: ele.id,
                title: ele.title,
                varient: ele.defaultVarient,
                image: ele.image,
                sequence: i
            }
            return obj;
        })
        const obj = {
            "type": "bucket",
            "language": lang,
            "names": [{
                default:englishLang,
                hi: hindiLang,
                mr: marathiLang,
                en: englishLang,
                kn: kannadaLang,
                te: teluguLang,
                pa: punjabiLang,
                ta: tamilLang,
                gu: gujaratiLang,

                    or:odisaLang,
                    ur:urduLang,
                    bn:bengaliLang,
                    ml:malayalamLang,
                    si:sinhalaLang,
                    ar:arabicLang,
                    af:afganiLang,
                    bur:burmeseLang,
                    khm:khmerLang

                   
            }],
            "media": media,
            "enabled": str2bool(initialValues.enable),
            "live_date_time": liveDate === null ? '' : liveDate,
            "expiry": expiryDate === null ? '' : expiryDate,
            "row": initialValues.row,
            "cms_name": initialValues.cms_name,
            "url":initialValues.url,
            "shortDescription":initialValues.shortDescription,
            "size": initialValues.size,
            "more": str2bool(initialValues.more),
            "bucketStyle": bucketStyle,
            "queryData": QueryData,
            "query": query,
            "tag": tag,
            "page": singlePage,
            "contentType": content,
            "magnification":initialValues.magnification,
            "storeId":localStorage.getItem("storeId"),
            "continueStatus":str2bool(continueToggle),
            "proUser":initialValues.proUser
        }
        postBucketApi(obj)
        setInitialValues(initialState);
        setQueryResult([]);
        setQuery([]);
        setHindiLang("");
        setMarathiiLang("");
        setKannadaLang("");
        setTeluguLang("");
        setPunjabiLang("");
        setTamilLang("");
        setGujaratiLang("");
        setMedia("");
        setTag([])
        setLeftData([]);
        setText("");
        setOption3("");
        setBucketStyle("");
        setLiveDate(null);
        setExpiryDate(null)
    }

    const onSerachQuery = () => {
        console.log(query)
        let array;
        const obj = query.map(ele => {
            let newQuery = {
                "AND_OR": ele.AND_OR,
                "field": ele.field.value,
                "operators": ele.operators.toString().replace(' ', '_').toLowerCase(),
                "text": ele.text.value ? ele.text.Description : ele.text.Description ? ele.text.typeid : ele.text

            }
            return newQuery
        })
        let newObj = {
            'query': obj,
            "storeId":localStorage.getItem("storeId"),
            "type":content
        }
        
        array = (query[1].text).split(',')
        console.log('----',array);
        console.log(newObj)
        postQuery(newObj,array);
    }

    const onClear = () => {
        setInitialValues(initialState);
        setQueryResult([]);
        setQuery([]);
        setHindiLang("");
        setMarathiiLang("");
        setKannadaLang("");
        setTeluguLang("");
        setPunjabiLang("");
        setTamilLang("");
        setGujaratiLang("");
        setMedia("");
        setTag([])
        setLeftData([]);
        setBucketStyle("");
        setLiveDate(null);
        setExpiryDate(null)
    }

    const deleteImg = (e, i) => {
        let newArray = media.filter((ele, index) => {
            return index !== i;
        })

        setMedia(newArray)
    }


    return (
        <Fragment>
            <Paper component={Box} p={4}>
                <div >
                    <h1 className="m-3 text-center">Add Bucket</h1>
                    <Grid container >
                        <BucketForm
                            onHandleChangeData={onHandleChangeData}
                            initialValues={initialValues}
                            medialHandle={medialHandle}
                            toggle={toggle}
                            bucketStyle={bucketStyle}
                            bucketStyleChange={bucketStyleChange}
                            pages={pages}
                            singlePage={singlePage}
                            handlePageChange={handlePageChange}
                            liveDate={liveDate}
                            expiryDate={expiryDate}
                            onLiveDateChange={onLiveDateChange}
                            onexpiryDateChange={onexpiryDateChange}
                            tag={tag}
                            handleTagChange={handleTagChange}
                            MenuProps={MenuProps}
                            radioList={radioList}
                            langList={langList}
                            lang={lang}
                            handleChangeLang={handleChangeLang}
                            media={media}
                            deleteImg={deleteImg}
                            bucketSTyleList={bucketSTyleList}
                            content={content}
                            handleContentChange={handleContentChange}
                            content_type={localStorage.getItem("userRole") != "SuperAdmin" ? content_type : game_content_type}
                            setEnglishLang={setEnglishLang}
                            englishLang = {englishLang}
                            handleChangeToggle = {handleChangeToggle}
                            continueToggle ={continueToggle}
                            setcontinueToggle = {setcontinueToggle}
                            handleproToggle = {handleproToggle}
                            proToggle ={proToggle}
                            setproToggle = {setproToggle}
                        />

                        <Grid item md={12} sm={12} style={{ marginTop: "30px" }}>
                            <h5>Bucket Query</h5>
                            <BucketQuery
                                option1={option1}
                                option2={option2}
                                option3={option3}
                                text={text}
                                query={query}
                                andOr={andOr}
                                optionOneHandleChange={optionOneHandleChange}
                                optionTwoHandleChange={optionTwoHandleChange}
                                optionThreeHandleChange={optionThreeHandleChange}
                                onTextChange={onTextChange}
                                onSubmitquery={onSubmitquery}
                                onAndOrChange={onAndOrChange}
                                ondeleteQuery={ondeleteQuery}
                                tags={tags}
                                content_type={localStorage.getItem("userRole") != "SuperAdmin" ? content_type : game_content_type}
                                fields={fields}
                                operators={operators}
                                onSerachQuery={onSerachQuery}
                                textRef={textRef}
                                queryContentType={queryContentType}

                            />
                        </Grid>
                        <Grid container alignItems="center" className={classes.root}>
                            <Grid item md={5}>
                                <h4>Selected Content Data</h4>
                                {left('Choices', leftData)}
                            </Grid>
                            <Grid item md={1}>
                                <Grid container direction="column" alignItems="center">
                                    <Button
                                        style={{ margin: "5px" }}
                                        variant="outlined"
                                        size="small"
                                        className={classes.button}
                                        onClick={handleCheckedRight}
                                        disabled={leftChecked.length === 0}
                                        aria-label="move selected right"
                                    >
                                        &gt;
                                    </Button>
                                    <Button
                                        style={{ margin: "5px" }}

                                        variant="outlined"
                                        size="small"
                                        className={classes.button}
                                        onClick={handleCheckedLeft}
                                        disabled={rightChecked.length === 0}
                                        aria-label="move selected left"
                                    >
                                        &lt;
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid item md={5}><h4>Content Data</h4>{right('Choices', queryResult)}


                            </Grid>
                        </Grid>

                        <Grid item md={12}>
                            <Button className="m-4" variant="contained" color="primary" onClick={onSubmit}>Submit</Button>
                            {/* <Button variant="contained" color="secondary" onClick={onClear}>Clear</Button> */}

                        </Grid>

                    </Grid>

                    <MultiLanguageModal modal={modal}
toggle={toggle}
englishLang={englishLang}
    hindiLang={ hindiLang}
    marathiLang={ marathiLang}
    kannadaLang={ kannadaLang}
    teluguLang={ teluguLang}
    punjabiLang={ punjabiLang}
    tamilLang={ tamilLang}
    gujaratiLang={ gujaratiLang}

    malayalamLang={ malayalamLang}
    bengaliLang={ bengaliLang}
    odisaLang={ odisaLang}
    urduLang={ urduLang}
    sinhalaLang={sinhalaLang}
    khmerLang = {khmerLang}
    arabicLang = {arabicLang}
    burmeseLang ={burmeseLang}
    afganiLang ={afganiLang}
    
    setAfganiLang ={setAfganiLang}
    setBurmeseLang ={setBurmeseLang}
    setEnglishLang = {setEnglishLang}
    setHindiLang= {setHindiLang}
    setMarathiiLang= {setMarathiiLang}
    setKannadaLang={setKannadaLang}
    setTeluguLang={setTeluguLang}
    setPunjabiLang={setPunjabiLang}
    setTamilLang={setTamilLang}
    setGujaratiLang={setGujaratiLang}
    setOdisaLang={setOdisaLang}
    setMalayalamLang={setMalayalamLang}
    setBengaliLang={setBengaliLang}
    setUrduLang={setUrduLang}
    setSinhalaLang = {setSinhalaLang}
    setKhmerLang = {setKhmerLang}
    setArabicLang = {setArabicLang}
>
    
</MultiLanguageModal>
                    {/* Modal */}
                    {/* <Modal style={{ marginTop: "100px" }} isOpen={modal} toggle={toggle}>
                        <ModalHeader toggle={toggle}>MultiLanguage</ModalHeader>
                        <ModalBody>
                            <TextField className="mt-2" variant="outlined" fullWidth width="25" required label="English" name="name" value={initialValues.name} onChange={onHandleChangeData}>
                            </TextField>
                            <ReactTransliterate
                                renderComponent={(props) => {
                                    const inputRef = props.ref;
                                    delete props["ref"];
                                    return <TextField className="mt-2" variant="outlined" fullWidth width="25" required label="Hindi" {...props} inputRef={inputRef} />;
                                }}
                                value={hindiLang}
                                onChangeText={(text) => {
                                    setHindiLang(text);
                                }}
                                lang="hi"
                            />
                          
                            <ReactTransliterate
                                renderComponent={(props) => {
                                    const inputRef = props.ref;
                                    delete props["ref"];
                                    return <TextField className="mt-2" variant="outlined" fullWidth width="25" required label="Marathi" {...props} inputRef={inputRef} />;
                                }}
                                value={marathiLang}
                                onChangeText={(text) => {
                                    setMarathiiLang(text);
                                }}
                                lang="mr"
                            />
                           
                            <ReactTransliterate
                                renderComponent={(props) => {
                                    const inputRef = props.ref;
                                    delete props["ref"];
                                    return <TextField className="mt-2" variant="outlined" fullWidth width="25" required label="Kannada" {...props} inputRef={inputRef} />;
                                }}
                                value={kannadaLang}
                                onChangeText={(text) => {
                                    setKannadaLang(text);
                                }}
                                lang="kn"
                            />
                          
                            <ReactTransliterate
                                renderComponent={(props) => {
                                    const inputRef = props.ref;
                                    delete props["ref"];
                                    return <TextField className="mt-2" variant="outlined" fullWidth width="25" required label="Telugu" {...props} inputRef={inputRef} />;
                                }}
                                value={teluguLang}
                                onChangeText={(text) => {
                                    setTeluguLang(text);
                                }}
                                lang="te"
                            />
                         
                            <ReactTransliterate
                                renderComponent={(props) => {
                                    const inputRef = props.ref;
                                    delete props["ref"];
                                    return <TextField className="mt-2" variant="outlined" fullWidth width="25" required label="Punjabi" {...props} inputRef={inputRef} />;
                                }}
                                value={punjabiLang}
                                onChangeText={(text) => {
                                    setPunjabiLang(text);
                                }}
                                lang="pa"
                            />
                            
                            <ReactTransliterate
                                renderComponent={(props) => {
                                    const inputRef = props.ref;
                                    delete props["ref"];
                                    return <TextField className="mt-2" variant="outlined" fullWidth width="25" required label="Tamil" {...props} inputRef={inputRef} />;
                                }}
                                value={tamilLang}
                                onChangeText={(text) => {
                                    setTamilLang(text);
                                }}
                                lang="ta"
                            />
                           
                            <ReactTransliterate
                                renderComponent={(props) => {
                                    const inputRef = props.ref;
                                    delete props["ref"];
                                    return <TextField className="mt-2" variant="outlined" fullWidth width="25" required label="Gujrati" {...props} inputRef={inputRef} />;
                                }}
                                value={gujaratiLang}
                                onChangeText={(text) => {
                                    setGujaratiLang(text);
                                }}
                                lang="gu"
                            />

<ReactTransliterate
                                renderComponent={(props) => {
                                    const inputRef = props.ref;
                                    delete props["ref"];
                                    return <TextField className="mt-2" variant="outlined" fullWidth width="25" required label="Odia" {...props} inputRef={inputRef} />;
                                }}
                                value={odisaLang}
                                onChangeText={(text) => {
                                    setOdisaLang(text);
                                }}
                                lang="gu"
                            />
                            <ReactTransliterate
                                renderComponent={(props) => {
                                    const inputRef = props.ref;
                                    delete props["ref"];
                                    return <TextField className="mt-2" variant="outlined" fullWidth width="25" required label="Malayalam" {...props} inputRef={inputRef} />;
                                }}
                                value={malayalamLang}
                                onChangeText={(text) => {
                                    setMalayalamLang(text);
                                }}
                                lang="ml"
                            />
                            <ReactTransliterate
                                renderComponent={(props) => {
                                    const inputRef = props.ref;
                                    delete props["ref"];
                                    return <TextField className="mt-2" variant="outlined" fullWidth width="25" required label="Bengali" {...props} inputRef={inputRef} />;
                                }}
                                value={bengaliLang}
                                onChangeText={(text) => {
                                    setBengaliLang(text);
                                }}
                                lang="bn"
                            />
                            <ReactTransliterate
                                renderComponent={(props) => {
                                    const inputRef = props.ref;
                                    delete props["ref"];
                                    return <TextField className="mt-2" variant="outlined" fullWidth width="25" required label="Urdu" {...props} inputRef={inputRef} />;
                                }}
                                value={urduLang}
                                onChangeText={(text) => {
                                    setUrduLang(text);
                                }}
                                lang="ur"
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={toggle}>Save</Button>{' '}
                            <Button color="secondary" onClick={toggle}>Cancel</Button>
                        </ModalFooter>
                    </Modal> */}
                </div>
            </Paper>

        </Fragment>
    )
}
