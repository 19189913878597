import React from 'react'
import {
    Grid,
    TextField,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    Button,
    MenuItem,
    InputLabel,
    Select,
    Input,
    Checkbox,
    ListItemText,
    IconButton,
    Tooltip
} from '@material-ui/core';
import { useStyles } from '../../styles/style';
import CancelIcon from '@material-ui/icons/Cancel';
import DateFnsUtils from "@date-io/date-fns";
import {
    DateTimePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import ClearIcon from "@material-ui/icons/Clear";
import Switch from "react-switch";
import InfoIcon from "@material-ui/icons/InfoRounded"

export default function BucketForm(props) {
    const classes = useStyles();
    return (
        <>
            <Grid item md={6} sm={12}>
                <FormControl variant="outlined" className={classes.formControl} >
                    <InputLabel id="demo-simple-select-outlined-label">Language</InputLabel>

                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={props.lang}
                        onChange={props.handleChangeLang}
                        label="Language"
                    >
                        {
                            props.langList && props.langList.length > 0 ?
                                props.langList.map((element, id) => {

                                    return (
                                        <MenuItem key={id} value={element.name}>{element.name}</MenuItem>
                                    )
                                }) :
                                <MenuItem disabled value="no pages">No Pages</MenuItem>

                        }

                    </Select>

                </FormControl>

            </Grid>
            <Grid item md={6}>

            <FormControl variant="outlined" className={classes.formControl} >
                    <InputLabel id="demo-simple-select-outlined-label">Content Type</InputLabel>

                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={props.content}
                        onChange={props.handleContentChange}
                        label="Content type"
                    >
                        {
                            props.content_type && props.content_type.length > 0 ?
                                props.content_type.map((element, id) => {

                                    return (
                                        <MenuItem key={id} value={element.Description}>{element.Description}</MenuItem>
                                    )
                                }) :
                                <MenuItem disabled value="no pages">No Content Type</MenuItem>

                        }

                    </Select>

                </FormControl>



            </Grid>

            <Grid item md={6} sm={12}>
                <TextField variant="outlined" className={classes.inputFieldBucket} width="25" required label="Name" name="name" value={props.englishLang} onChange={(e)=>props.setEnglishLang(e.target.value)} >
                </TextField>
                <Button style={{ height: "56px" }} variant="contained" onClick={props.toggle}>Multi-Language</Button>
            </Grid>
            <Grid item md={6} sm={12}>
                <TextField variant="outlined" className={classes.inputField} width="25" required label="CMS name" name="cms_name" value={props.initialValues.cms_name} onChange={props.onHandleChangeData} >
                </TextField>
            </Grid>
            <Grid item md={6} sm={12}>
                <TextField variant="outlined" className={classes.inputField} width="25" required label="Row" name="row" value={props.initialValues.row} onChange={props.onHandleChangeData} >
                </TextField>

            </Grid>
            
            <Grid item md={6} sm={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker label="Live Date time" className={classes.inputField} name="liveDate" value={props.liveDate} onChange={props.onLiveDateChange} ></DateTimePicker>
                    <IconButton
                        style={{ marginTop: "25px" }}
                        edge="end"
                        size="small"
                        disabled={!props.liveDate}
                        onClick={() => props.onLiveDateChange(null)}
                    >
                        <ClearIcon />
                    </IconButton>

                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item md={6} sm={12}>
                <TextField variant="outlined" className={classes.inputField} width="25" required label="Size" name="size" value={props.initialValues.size} onChange={props.onHandleChangeData} >
                </TextField>

            </Grid>
            <Grid item md={6} sm={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker label="Expiry Date time" className={classes.inputField} name="expiryDate" value={props.expiryDate} onChange={props.onexpiryDateChange} ></DateTimePicker>
                    <IconButton
                        style={{ marginTop: "25px" }}
                        edge="end"
                        size="small"
                        disabled={!props.expiryDate}
                        onClick={() => props.onexpiryDateChange(null)}
                    >
                        <ClearIcon />
                    </IconButton>

                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item md={6} sm={12}>
            <TextField type="number" InputProps={{ inputProps: { min: 0, max: 50 } }} variant="outlined" className={classes.inputField} width="25" required label="Bucket Style" name="bucketStyle" value={props.bucketStyle} onChange={props.bucketStyleChange} >
                </TextField>
            </Grid>

            <Grid item md={6} sm={12}>
            
            <div style={{ "display":"flex", "flex-direction":"row" }}><Tooltip title="to create continuity bucket please select multiple media content"><InfoIcon/></Tooltip>&nbsp;<div>Continuity Bucket :</div>&nbsp;
            <div><Switch 
            onChange={props.handleChangeToggle} value={props.continueToggle} checked={props.continueToggle}
             />
             </div>
             </div>
                </Grid>
                {/* <Grid item md={6} sm={12}>
            
            <div style={{ "display":"flex", "flex-direction":"row" }}><Tooltip title="to create pro user bucket"><InfoIcon/></Tooltip>&nbsp;<div>Pro User :</div>&nbsp;
            <div><Switch 
            onChange={props.handleproToggle} value={props.proToggle} checked={props.proToggle}
             />
             </div>
             </div>
                </Grid> */}
                 <Grid item md={6} sm={12}>
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Pro User</InputLabel>

                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={props.initialValues.proUser}
                        onChange={props.onHandleChangeData}
                        label="proUser"
                        name="proUser"
                    >
                    <MenuItem  value="0">Free User</MenuItem>
                    <MenuItem  value="1">Pro User</MenuItem>
                    <MenuItem  value="2">Both</MenuItem>
                                    
                    </Select>

                </FormControl>

            </Grid>
                <Grid item md={6} sm={12}>
                <TextField variant="outlined" disabled={props.continueToggle == false} className={classes.inputField} width="25" required label="Short Description" name="shortDescription" value={props.initialValues.shortDescription} onChange={props.onHandleChangeData} >
                </TextField>

            </Grid>
            <Grid item md={6} sm={12}>
                <TextField variant="outlined" disabled={props.continueToggle == false}  className={classes.inputField} width="25" required label="Url" name="url" value={props.initialValues.url} onChange={props.onHandleChangeData} >
                </TextField>

            </Grid>
            <Grid item md={6} sm={12}>
                <FormControl className={classes.formControl}>
                    <FormLabel className={classes.lables} component="div" >Enabled</FormLabel>
                    <RadioGroup aria-label="enable" name="enable" value={props.initialValues.enable} onChange={props.onHandleChangeData} row>

                        <FormControlLabel value="true" control={<Radio />} label="true" />
                        <FormControlLabel value="false" control={<Radio />} label="false" />

                    </RadioGroup>
                </FormControl>

            </Grid>
            <Grid item md={6} sm={12}>
                <Button
                    className={classes.upload}
                    variant="outlined"
                    component="label"

                >
                    Media
                    <input
                        multiple
                        type="file"
                        hidden
                        onChange={props.medialHandle}
                        onClick={(event) => {
                            event.target.value = null
                        }}
                    />
                </Button>

                {props.media && props.media.length > 0 ?
                    (props.media.map((ele, i) => (<div><p className="mt-2 ml-4" style={{cursor:"pointer",display:"inline"}} onClick={()=>window.open(ele.name?null:`https://storage.googleapis.com/media-content-hungama-com/bucket/${ele}`, "_blank")}>{ele.name ? ele.name : ele} </p><CancelIcon style={{cursor:"pointer"}} onClick={(e) => props.deleteImg(e, i,ele)} /></div>)))
                    : null}

            </Grid>
            <Grid item md={6} sm={12}>
                <FormControl className={classes.formControl}>
                    <FormLabel className={classes.lables} component="div" >More</FormLabel>
                    <RadioGroup aria-label="enable" name="more" value={props.initialValues.more} onChange={props.onHandleChangeData} row>

                        <FormControlLabel value="true" control={<Radio />} label="true" />
                        <FormControlLabel value="false" control={<Radio />} label="false" />

                    </RadioGroup>
                </FormControl>

            </Grid>
            <Grid item md={6} sm={12}>
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Magnification</InputLabel>

                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={props.initialValues.magnification}
                        onChange={props.onHandleChangeData}
                        label="Magnification"
                        name="magnification"
                    >
                                        <MenuItem  value="1.75">1.75</MenuItem>
                                        <MenuItem  value="1.55">1.50</MenuItem>
                                        <MenuItem  value="1">1</MenuItem>
                                        <MenuItem  value="0.50">0.50</MenuItem>c
                                        <MenuItem  value="0.75">0.75</MenuItem>
                                    
                    </Select>

                </FormControl>

            </Grid>
            <Grid item md={6} sm={12}>
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Page</InputLabel>

                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={props.singlePage}
                        onChange={props.handlePageChange}
                        label="Pages"
                    >
                        {
                            props.pages && props.pages.length > 0 ?
                                props.pages.map((element, id) => {

                                    return (
                                        <MenuItem key={id} value={element._id}>{element.title.en} ({element.pageCode})</MenuItem>
                                    )
                                }) :
                                <MenuItem disabled value="no pages">No Pages</MenuItem>

                        }

                    </Select>

                </FormControl>

            </Grid>
            <Grid item md={6}>
                <FormControl className={classes.formControl} >
                    <InputLabel id="demo-mutiple-checkbox-label">Radio</InputLabel>
                    <Select
                        labelId="demo-mutiple-checkbox-label"
                        id="demo-mutiple-checkbox"
                        multiple
                        value={props.tag}
                        onChange={props.handleTagChange}
                        input={<Input />}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={props.MenuProps}
                    >
                        {props.radioList.map((name) => (
                            <MenuItem key={name.id} value={name.value}>
                                <Checkbox checked={props.tag.indexOf(name.value) > -1} />
                                <ListItemText primary={name.name} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

            </Grid>
            



        </>
    )
}
